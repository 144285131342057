.App {
  text-align: center;
  font-family: 'Playfair Display', serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.mobileHide {
  display: flex;
  width: 100%;
  /* margin: auto; */
}

.white {
  color: white;
}

a {
  color: white !important;
  text-decoration: none !important;
}

html {
  box-sizing: border-box;
  scroll-behavior: smooth;

}

/* #header {
  display: flex;
  justify-content: space-between;
  margin: 5px auto;
} */

.searchBar {
  align-self: center;
  width: 30vw !important;
}


.Auth-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Auth-form {
  width: 620px;
  box-shadow: rgb(0 0 0 16%) 1px 1px 10px;
  padding-top: 30px;
  padding-bottom: 20px;
  border-radius: 8px;
  background-color: white;
  margin: auto;
  margin-top: 20px
}

.Auth-form-content {
  margin-left: 12%;
  margin-right: 12%;
}

.Auth-form-title {
  text-align: center;
  margin-bottom: 1em;
  font-size: 24px;
  color: rgb(34, 34, 34);
  font-weight: 800;
}

label {
  font-size: 14px;
  font-weight: 600;
  color: rgb(34, 34, 34);
}

.errorMessage {
  color: red;
}

.carousel-inner {
  max-width: 100vw !important;
  margin: auto;
}

.homeSlideImg {
  height: 480px;
  width: 80% !important;
  object-fit: cover;
  margin: auto
}

.fontAwesomeIcon {
  margin: auto;
}

.prograssCircle {
  margin: auto;
}

.MuiDrawer-paper {
  width: 1000px !important;
}

.css-1160xiw-MuiPaper-root-MuiDrawer-paper {
  background-color: white !important;
}

h3 {
  text-align: center;
}

.productsContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start
}


#root {
  /* max-width: 2560px; */
  margin: 0 auto;
  /* padding: 1rem; */
  text-align: center;
  height: 100vh;
}

.app {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.text-input {
  height: 50px;
  width: 100%;
  margin-bottom: 20px;
  border: 2px solid lightgrey;
  padding: 10px;
  font-size: 14px;
  border-radius: 5px;
}

.result-image {
  margin-top: 20px;
  border: 2px solid lightgray;
  padding: 10px;
  width: 100%;
  object-fit: contain;
}

.card {
  padding: 2em;
  display: flex;
  flex-direction: column;
}

.ophie {
  font-family: 'Rubik Doodle Shadow', system-ui;
}

.left:hover {
  background: rgba(0, 0, 0, 0.2);
}

.right:hover {
  background: gray;
  opacity: 0.2;
}

#leftButton {
  opacity: 1 !important
}

.gptContainer {
  width: 100vw;
  height: 60vh;
  /* margin: auto; */
}

.previousPrompt {
  display: block;
  padding: 5px 5px;
}


/* src/components/LandingPage.css */
.landing-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
}

.container {
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
}

.chat-section .image-generator-section {
  display: flex;
  background: rgba(0, 0, 0, 0.1);
  ;
  padding: 60px 0;
  width: 50vw
}

h2 {
  font-size: 2.5em;
  margin-bottom: 16px;
  color: #333;
}

p {
  margin-bottom: 0 !important;
}



button {
  padding: 14px 28px;
  font-size: 1.2em;
  cursor: pointer;
  border: none;
  border-radius: 6px;
  outline: none;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.explore-button {
  background: linear-gradient(to right, #3498db, #2980b9);
  color: #fff;
  box-shadow: 0 4px 10px rgba(52, 152, 219, 0.3);
  margin-top: 15px;
  /* transform: translateX(-50%); */
  width: 200px;
}

.generate-button {
  background: linear-gradient(to right, #6c757d, #5a6268);
  color: #fff;
  box-shadow: 0 4px 10px rgba(108, 117, 125, 0.3);
}

button:hover {
  background-color: #2980b9;
}

.homeSearchInput {
  width: 100%
}

.lighter {
  font-weight: lighter;
}

.card {
  width: 33%;
  height: 400px !important;
  margin: 15px;

}

.componentContainer {
  display: flex;
  min-height: 100vh;
  margin: auto;
  justify-content: space-around;
}

.imageAiCantainer {
  margin: auto;
}

/* .custom-toggler .navbar-toggler-icon {
  color: white !important;
} */
#navigation {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 300px;
}

/* .textAiContainer {
  display: flex;
  height: 400px;
} */

.textAiContainerLeft img {
  width: 400px;
  height: auto;
  float: right;
  margin-right: 15px
}

.textAiContainerRight {
  width: 50%;
  text-align: left
}

.footerContainer {
  display: flex;
  justify-content: space-around;
  background-color: black;
  font-size: 15px;
}

.footerContent {
  width: 33%;
  padding: 25px;
  margin: 15px;
  text-align: left;
}

@media only screen and (max-width: 600px) {
  .footerContainer {
    display: block
  }

  .footerContent {
    width: 100%;
    margin-top: 0;
  }

  .textAiContainer {
    display: block !important;
    height: 650px !important;
  }

  .textAiContainerLeft {
    margin: auto !important;
    width: 100vw !important;
  }

  .textAiContainerLeft img {
    float: none !important;
    padding: 15px;
    margin: auto !important;

  }

  .textAiContainerRight {
    padding: 25px !important;
  }

  .imageAiContainer {
    height: 400px !important;
  }

  #navigation {
    align-items: center;
    height: 250px;
  }

  a {
    color: black !important
  }

  .colorCard {
    display: block !important;
    width: 100%;
  }

  .colorCardItem {
    width: 100% !important;
    height: 250px !important;
    /* margin: auto; */
  }

  .componentContainer {
    display: block;
  }

  .card {
    width: 90vw
  }

  html,
  body {
    width: 100%;
    overflow-x: hidden;
  }

  .chat-section {
    width: 100%
  }

  .gptContainer {
    width: 100vh;

  }

  .image-generator-section {
    width: 100%
  }

  .mobileHide {
    display: none
  }

  .mobileHideItem {
    font-size: 25px;
    padding: 0 12px;
  }

  .Auth-form-content {
    width: 100%;
    margin: 0;
    padding: 0 5% 0 5%;
  }

  #header {
    display: none
  }

  #Masonry {
    width: 50%;
    object-fit: scale-down
  }

  .productDetailContainer {
    margin: auto !important;
  }

  .MuiDrawer-paper {
    width: 80% !important
  }

  .cartItemContent {
    font-size: 0.5rem
  }

  ul {
    padding: 0 !important;
  }

  ul p {
    font-size: 10px;
  }

  .homeSearchInput {
    width: 90vw
  }

  .landing-page {
    min-height: 100vh;
    margin-top: 20px
  }
}